<template>
  <!-- Modal dialog: WARM -->
  <v-dialog 
    transition="dialog-top-transition" 
    v-model="dialog" width="500" 
    @keydown.esc="cancel" 
    persistent
  >
    <v-card-title class="dialogTitleClass bg-dialog-warm">
      <v-icon class="mr-2 icon-warm">mdi-alert</v-icon>{{ title }}
    </v-card-title>
    <v-card-text v-show="!!message" class="dialogTextClass" v-html="message"></v-card-text>
    <v-divider></v-divider>
    <v-row class="ma-0 pa-0">
      <v-col>
        <v-btn class="btn-back" @click.native="no">
          <span class="s-text-white-bold">NO</span>
        </v-btn>        
      </v-col>
      <v-col>
        <v-btn class="btn-warm" @click.native="yes">
          <span class="s-text-white-bold">SI</span>
        </v-btn>        
      </v-col>
    </v-row>
  </v-dialog>
</template>

<script>
  export default {
    name: "DialogWarm",
    data() {
      return {
        dialog: false,
        resolve: null,
        reject: null,
        message: null,
        action: null,
        title: null,
        options: {
          noconfirm: false,
        },
      };
    },
    methods: {
      open(title, message, action, options) {
        this.dialog = true;
        this.title = title;
        this.message = message;
        this.action = action;
        this.options = Object.assign(this.options, options);
        return new Promise((resolve, reject) => {
          this.resolve = resolve;
          this.reject = reject;
        });
      },
      yes() {
        this.resolve(false);
        this.dialog = false;
        this.$emit('saveConfig', {res: true, action: this.action});
      },
      no() {
        this.resolve(false);
        this.dialog = false;
      },
    },
  };
</script>